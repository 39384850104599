.team-container
{
    width:100%;
    height: auto;
 
}
.row
{
    width: 100%;
    margin:auto;
    margin-bottom: 40px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: center;
    justify-content: center;
}
@media(max-width: 768px)
{
    .row{
        width: 100%;
    }
}
.profile-card
{
    background-color: #ffffff;
    width: 250px;
    height: 250px;
    margin: 20px 10px 10px 10px;
}
@media(max-width: 468px)
{
    .profile-card
    {
        width: 290px;
        height: 250px;
    }
}
.profile-radius{
    border-radius: 10%;
  }
.profile-content
{
    padding:15px;
}
.img{
    width: 175px !important;
    height: 175px !important ;
    border-radius: 100px !important ;
}
.profile-image
{
    text-align: center;
}

.desc p{
    font-size: 14px;
    line-height: 1.3em; 
    margin-top: 10px;
    color: #000000;     
}

h5{
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 10px;
    text-align: center;
}
.desc{
    text-align: justify;
    margin: 0;
    padding: 4%;
}
